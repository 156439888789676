import React from "react";

import landing from '../media/cover.png'
import styles from './Header.module.css'
import './Header.css'

function Header() {
    const goToElement = (id) => {
        const elem = document.getElementById(id); 
        window.scrollTo({
          top:elem.offsetTop,
          behavior:"smooth"
      });
    };

    return ( 
    <div>
        <div className={styles.headerContainer}>
            <div className={styles.logoContainer}>
                <p>PETAR KOŠI</p>
            </div>
            <div className={styles.navigationContainer}>
                <button onClick={() => goToElement("artworks")}>ARTWORKS</button>
                <button onClick={() => goToElement("exhibitions")}>EXHIBITIONS</button>
                <button onClick={() => goToElement("projects")}>PROJECTS</button>
                <button onClick={() => goToElement("biography")}>BIOGRAPHY</button>
                <button onClick={() => goToElement("contact")}>CONTACT</button>
            </div>
        </div>
        <div className={styles.landingContainer}>
            <div className={styles.landingImgContainer}>
                <img src={landing} className={styles.landingImg}/>
            </div>
        </div>
        <div style={{textAlign: 'center', color: 'white', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
            <span className={styles.landingImgDescription} style={{width: '70%'}}>
            PETAR KOŠI IS AN ARTIST FROM CROATIA BASED IN ZAGREB, CROATIA. HE HAS BEEN DOING SCULPTURES, PAINTINGS AND DRAWINGS AND OTHER VISUAL PROJECTS... FEEL FREE TO LOOK AROUND...
            </span>
        </div>
    </div>);
}

export default Header;