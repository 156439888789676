import React, { useState }  from 'react';

import "./App.css";
import Header from "./layout/Header";
import Music from "./layout/Music";
import Contact from './layout/Contact';
import About from './layout/About';

import { slide as Menu } from 'react-burger-menu'

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  return (
    <div id="outer-container">
      <Menu isOpen={isMenuOpen} onStateChange={handleStateChange} className="mobile-nav-container" reveal right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
          <a id="home" onClick={() => handleCloseMenu()} className="menu-item" href="#">PETAR KOŠI</a>
          <a id="artworks" onClick={() => handleCloseMenu()} className="menu-item" href="#artworks">ARTWORKS</a>
          <a id="exhibitions" onClick={() => handleCloseMenu()} className="menu-item" href="#exhibitions">EXHIBITIONS</a>
          <a id="projects" onClick={() => handleCloseMenu()} className="menu-item" href="#projects">PROJECTS</a>
          <a id="biography" onClick={() => handleCloseMenu()} className="menu-item" href="#biography">BIOGRAPHY</a>
          <a id="contact" onClick={() => handleCloseMenu()} className="menu-item" href="#contact">CONTACT</a>
      </Menu>
      <div id="page-wrap" className="App">
        <Header />
        <Music />
        <About />
        <Contact />
      </div>
    </div>
  );
}

export default App;
