import React from 'react';
import './Music.css'

import kosi from '../media/pero.jpg'

function About() {
    return ( 
        <>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "20px"}}>
                <img style={{width: "30%"}} src={kosi}></img>
                <div style={{width: "65%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span>Petar Koši, born on July 1, 1995 in Zagreb. In 2011, he entered the School of Applied Arts and Design in Zagreb, where he graduated in 2014 as an arrange- ment and set designer. The following year, in 2015, he entered the Academy of Fine Arts in Zagreb, majoring in sculpture. Through the student exchange program in 2018. enters the Universidad de Bellas Artes in Granada, in the Kingdom of Spain, and completes IV. year of undergraduate studies. In 2020, he graduated from the Academy of Fine Arts in Zagreb, and obtained the academic title of Master of Sculpture. During his studies, he won two Rector's awards for team scientific and artistic work, and the ALU Academic Council award to the most successful graduates. He participated in many group exhibi- tions, fairs and sculpture festivals, and exhibits independently. He is a member of HULULK and HDLU. He exhibits in the country and abroad.</span>
                </div>
            </div>
        </>
    );
}

export default About;