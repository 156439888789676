import React, {useState, useRef, useEffect} from 'react';
import './Music.css'

import luxaeterna from '../media/exhibitions/luxaeterna.jpg';
import ivanic from '../media/exhibitions/ivanic.jpg';
import bodyintransition from '../media/exhibitions/bodyintransition.jpg';
import egoeros from '../media/exhibitions/Ego eros.jpg';
import briefcomments from '../media/exhibitions/briefcomments.jpg';
import armourdown from '../media/exhibitions/armourdown.jpg';
import forselfportrait from '../media/exhibitions/forselfportrait.jpg';
import sesvete from '../media/exhibitions/10360.jpg';

function Exhibitions() {
    return ( 
        <>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>10360, </span>
                    <span>solo exhibition, collaboration with Hrvoje Glavan, Gallery ZIPS, Zagreb, 2023</span>
                </div>
                <img style={{width: "45%"}} src={sesvete}></img>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "20px"}}>
                <img style={{width: "45%"}} src={luxaeterna}></img>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>LUX AETERNA, </span>
                    <span>solo exhibition, Vrba Gallery, Široki Brijeg, Bosnia and Herzegovina, 2023</span>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>DISAPPEARING TO BECOME, </span>
                    <span>solo exhibition, collaboration with Lucija Jelić, Gallery of Open University Ivanić Grad, Ivanić Grad, 2023</span>
                </div>
                <img style={{width: "45%"}} src={ivanic}></img>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <img style={{width: "45%"}} src={bodyintransition}></img>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>BODY IN TRANSITION - MATTER AND SPIRIT RELATION, </span>
                    <span>Galerie KUK 44, Pfaffenhofen an der Ilm, Germany, 2022</span>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>EGO - EROS /intersection, </span>
                    <span>solo exhibition, collaboration with Lucija Jelić, Oblok Gallery, National University Sesvete, Zagreb, 2022</span>
                </div>
                <img style={{width: "45%"}} src={egoeros}></img>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <img style={{width: "45%"}} src={briefcomments}></img>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>"Brief comments", </span>
                    <span>solo exhibition, Gallery Kontrast, National University of Dubrava, Zagreb, 2022</span>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>/ARMOUR DOWN/, </span>
                    <span>solo exhibition, Gallery of Special Police, Zagreb, 2021</span>
                </div>
                <img style={{width: "45%"}} src={armourdown}></img>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                <img style={{width: "45%"}} src={forselfportrait}></img>
                <div style={{width: "45%", color: "white", fontSize: "24px", padding: "20px"}}>
                    <span style={{fontWeight: "bold"}}>"For Selfportrait", </span>
                    <span>solo exhibition, Razvid Gallery, POU Zaprešić, Zaprešić, 2020</span>
                </div>
            </div>
        </>
    );
}

export default Exhibitions;