import styles from "./Contact.module.css";
import React from "react";

import logo from '../media/logo.png'

function Contact() {
  return (
    <div id="contact" className={styles.contactContainer}>
      <div className={styles.title}>
        <p>Contact</p>
      </div>
      <div className={styles.subtitle}>
        <p>if you want to ask for an artwork, want to make an order, or simply want to say something, feel free to contact...</p>
      </div>
      <div className={styles.email}>
      <p><a style={{textDecoration: "none", color: "white"}} href="mailto:petar.kosiakapp@gmail.com">petar.kosiakapp@gmail.com</a></p>
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} style={{width: '20%'}} />
      </div>
      <div className={styles.footer}>
        <p>Petar Koši © all rights reserved 2024</p>
      </div>
    </div>
  );
}

export default Contact;
