import React, {useState, useRef, useEffect} from 'react';

import styles from './Music.module.css'
import './Music.css'
import Album from './Album';
import Paintings from './Paintings';
import Drawings from './Drawings';
import Exhibitions from './Exhibitions';

function Music() {
    return ( 
        <>
            <div id="artworks" className={styles.musicContainer} style={{width: "100%", justifyContent: "right"}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        ARTWORKS
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        materialised poetry...
                        </span>
                    </p>
                </div>
            </div>
            <div id="sculptures" className={styles.musicContainer} style={{width: "100%", justifyContent: "left", paddingTop: 0, marginTop: 0}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        SCULPTURES
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        when idea becomes reality...
                        </span>
                    </p>
                </div>
            </div>
            <Album></Album>
            <div id="paintings" className={styles.musicContainer} style={{width: "100%", justifyContent: "left", paddingTop: 0, marginTop: 0}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        PAINTINGS
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        one colour to another...
                        </span>
                    </p>
                </div>
            </div>
            <Paintings></Paintings>
            <div id="drawings" className={styles.musicContainer} style={{width: "100%", justifyContent: "left", paddingTop: 0, marginTop: 0}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        DRAWINGS
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        it all starts with one line...
                        </span>
                    </p>
                </div>
            </div>
            <Drawings></Drawings>
            <div id="exhibitions" className={styles.musicContainer} style={{width: "100%", justifyContent: "left", paddingTop: 0, marginTop: 0}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        EXHIBITIONS
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        if you have missed it live, you can still have a look...
                        </span>
                    </p>
                </div>
            </div>
            <Exhibitions></Exhibitions>
            <div id="about" className={styles.musicContainer} style={{width: "100%", justifyContent: "right"}}>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        BIOGRAPHY
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        a little bit about the author...
                        </span>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Music;