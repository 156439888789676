import React, {useState, useRef, useEffect} from 'react';
import { Image } from 'antd';
import './Music.css'

const images = require.context('../media/paintings', true);
const imageList = images.keys().map(image => images(image));

function Paintings() {
    return ( 
        <>
            <ul className="image-ul">
                    {imageList.map((sculpture) => 
                        (
                            <li className="image-li-item">
                                <Image width="100%" height="350px" style={{width: "100%", height: "350px", objectFit: "cover"}} src={sculpture}></Image>
                            </li>
                        )
                    )}
            </ul>
        </>
    );
}

export default Paintings;